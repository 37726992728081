<template>
  <v-col xs="6" sm="6" md="3" lg="2">
    <v-autocomplete
      item-text="label"
      item-value="key"
      :items="items"
      :rules="rules"
      v-model="itemValue"
      menu-props="closeOnContentClick"
      hide-no-data
      hide-selected
      name="status"
      @input="handleInput"
    >
      <template v-slot:label>{{ label }}<sup>*</sup> </template>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LanguageSetter",
  computed: {
    ...mapGetters("account/donor", ["availableLanguageLabels"]),
    items: function () {
      let items = [...this.availableLanguageLabels];
      items.unshift({
        label: this.$t("allValues"),
        key: null,
      });

      return items;
    },
  },
  props: {
    value: {
      type: Number,
    },
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
    },
  },
  data() {
    return {
      itemValue: this.value,
      closeOnContentClick: true,
      label: this.$t("account.attribute.language"),
      rules: [(value) => !!value || this.$t("account.requiredField")],
    };
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
  },
};
</script>
