<template>
  <div class="about">
    <v-row>
      <v-col>
        <h1>{{ $t("account.generationTitle") }}</h1>
      </v-col>
    </v-row>
    <v-form @submit.prevent="calculate" ref="calculateForm">
      <v-row>
        <component
          :is="item"
          v-for="(item, index) in paramsKeys"
          v-model="params[item]"
          :key="index"
          :label="labels[item]"
        ></component>
        <v-col xs="12" sm="6" lg="12" align-self="center">
          <v-btn
            small
            outlined
            color="primary"
            class="ml-2"
            type="submit"
            :loading="isCalculating"
            :disabled="isCalculating || disableCalculation"
          >
            {{ $t("account.calculate") }}
            <template v-slot:loader>
              <span>{{ $t("account.calculate") }}</span>
            </template>
          </v-btn>
          <v-btn
            small
            outlined
            color="error"
            class="ml-2"
            :disabled="isCalculating"
            @click="reset"
          >
            {{ $t("account.cancel") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div>
      <!-- Data loader -->
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <div
        class="v-sheet v-sheet--outlined theme--light pa-3 my-3"
        v-if="totalDataPresets > 0"
      >
        <!--    Data Presets table     -->
        <div>
          <span class="ml-2 body-2">
            {{ $t("account.total") }}: {{ totalDataPresets }}
          </span>
        </div>
        <v-data-iterator
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          :items="generation"
        >
          <template v-slot:header>
            <div
              v-if="totalDataPresets > 0"
              class="d-sm-none d-md-none d-lg-block px-4 py-0 pb-0"
            >
              <v-row align-self="center" class="grey--text body-2">
                <v-col class="align-self-end caption" sm="1" md="1" lg="1">
                  <div>#</div>
                </v-col>
                <v-col
                  class="align-self-end caption flex-nowrap"
                  sm="1"
                  md="1"
                  lg="1"
                >
                  <div>
                    {{ $t("account.attribute.gender") }} /
                    {{ $t("account.attribute.language") }}
                  </div>
                </v-col>
                <v-col class="align-self-end caption" sm="2" md="2" lg="2">
                  <span>{{ $t("account.attribute.age") }}</span>
                </v-col>
                <v-col class="align-self-end caption" sm="2" md="2" lg="2">
                  <span>{{ $t("account.attribute.city") }}</span>
                </v-col>
                <v-col class="align-self-end caption" sm="4" md="4" lg="3">
                  <span>{{ $t("account.coef") }}</span>
                </v-col>
                <v-col class="align-self-end caption" sm="2" md="2" lg="3">
                  <span>{{ $t("account.count") }}</span>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:default="props">
            <account-data-preset
              v-for="(item, i) in props.items"
              :ref="item.id"
              :key="i"
              :index="i"
              :accountData="item"
              :cities="cities"
            ></account-data-preset>
          </template>
        </v-data-iterator>
        <v-alert
          v-if="failedIds.length > 0"
          dense
          outlined
          type="error"
          class="mt-2 mb-4"
        >
          {{ $t("account.invalidGenerationAlert") }}
        </v-alert>
        <v-alert
          v-if="formattedGenerations.length === 0 && failedIds.length === 0"
          dense
          outlined
          type="warning"
          class="mt-2 mb-4"
        >
          {{ $t("account.emptyGenerationAlert") }}
        </v-alert>
        <v-btn
          small
          color="primary"
          class="mt-2 mb-1"
          :disabled="failedIds.length > 0 || formattedGenerations.length === 0"
          @click="generate"
        >
          {{ $t("account.generateData") }}
          <template v-slot:loader>
            <span>{{ $t("account.generateData") }}</span>
          </template>
        </v-btn>
      </div>
      <div v-if="totalCount > 0" class="my-3">
        <v-data-iterator
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          :items="items"
        >
          <template v-slot:header>
            <div class="d-sm-none d-md-none d-lg-block px-4 py-0 pb-0">
              <v-row align-self="center" class="grey--text body-2">
                <v-col md="1" lg="1" class="text-center align-self-end caption">
                  Id
                </v-col>
                <v-col md="2" lg="1" class="text-center align-self-end caption">
                  {{ $t("account.attribute.status") }}
                </v-col>
                <v-col
                  md="2"
                  lg="2"
                  class="text-center align-self-end caption flex-nowrap"
                >
                  {{ $t("account.attribute.gender") }} /
                  {{ $t("account.attribute.language") }} /
                  {{ $t("account.attribute.age") }}
                </v-col>
                <v-col md="1" lg="1" class="text-center align-self-end caption">
                  {{ $t("account.attribute.city") }}
                </v-col>
                <v-col md="3" lg="3" class="text-center align-self-end caption">
                  {{ $t("account.numFactGoal") }}
                </v-col>
                <v-col md="2" lg="3" class="text-center align-self-end caption">
                  {{ $t("account.attribute.createdAt") }}
                </v-col>
                <v-col md="1" lg="1" class="text-center align-self-end caption">
                  {{ $t("account.result") }}
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:default="props">
            <account-generation-item
              v-for="(item, i) in props.items"
              :key="i"
              :accountData="item"
              :cities="cities"
            ></account-generation-item>
          </template>
        </v-data-iterator>

        <div class="text-right">
          <span class="ml-2 body-2">
            {{
              $t("paginationSummary", {
                from: offset + 1,
                to: maxItem,
                totalCount: totalCount,
              })
            }}
          </span>
        </div>
      </div>
    </div>
    <!--    Pagination    -->
    <div v-if="pageCount > 1" class="text-center pt-6">
      <v-pagination
        v-model="pagination.page"
        :length="pageCount"
        :total-visible="9"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import GenderSetter from "@/views/account-generation/parts/GenderSetter";
import LanguageSetter from "@/views/account-generation/parts/LanguageSetter";
import AgeSetter from "@/views/account-generation/parts/AgeSetter";
import CitySetter from "@/views/account-generation/parts/CitySetter";
import CountSetter from "@/views/account-generation/parts/CountSetter";
import {
  CALCULATE_ACCOUNT_GENERATION,
  FETCH_ACCOUNT_GENERATIONS,
  FETCH_GENERATION_CITIES,
  PUT_ACCOUNT_GENERATION,
  RESET_GENERATION_PARAMS,
} from "@/store/actions/account-generation";
import { mapGetters, mapState } from "vuex";
import { paramsKeys } from "@/store/modules/account/generation/state";
import _ from "lodash";
import AccountDataPreset from "@/views/account-generation/item/AccountDataPreset";
import AccountGenerationItem from "@/views/account-generation/item/AccountGenerationItem";

export default {
  name: "AccountGeneration",
  components: {
    AccountGenerationItem,
    AccountDataPreset,
    numGoal: CountSetter,
    city: CitySetter,
    ageGroup: AgeSetter,
    gender: GenderSetter,
    language: LanguageSetter,
  },
  computed: {
    ...mapGetters("account/generation", [
      "offset",
      "itemsPerPage",
      "totalCount",
      "items",
      "pageCount",
      "pagination",
      "formattedGenerations",
    ]),
    ...mapState("account/generation", ["params", "generation"]),
    maxItem: function () {
      return Math.min(this.totalCount, this.offset + this.itemsPerPage);
    },
    totalDataPresets: function () {
      return this.generation ? this.generation.length : null;
    },
    disableCalculation: function () {
      let disable = true;
      const pattern = /^[0-9]+$/;
      if (
        !!this.params.language &&
        this.params.numGoal &&
        pattern.test(this.params.numGoal) &&
        parseInt(this.params.numGoal) > 0 &&
        parseInt(this.params.numGoal) <= 10000
      ) {
        disable = false;
      }
      return disable;
    },
  },
  data() {
    return {
      isCalculating: false,
      isGenerating: false,
      dataLoading: false,
      labels: {
        ageGroup: this.$t("account.attribute.age"),
        city: this.$t("account.attribute.city"),
        numGoal: this.$t("account.count"),
        gender: this.$t("account.attribute.gender"),
        language: this.$t("account.attribute.language"),
      },
      paramsKeys,
      currentRouteName: this.$route.name,
      cities: [],
      failedIds: [],
    };
  },
  methods: {
    handleInput: function () {},
    calculate: function () {
      this.isCalculating = true;
      this.$store
        .dispatch("account/generation/" + CALCULATE_ACCOUNT_GENERATION)
        .then(
          (res) => {
            this.isCalculating = false;
            if (res.status && res.data.total === 0) {
              this.$notify({
                group: "messages",
                text: this.$t("account.emptyGenerationButch"),
              });
            } else if (!res.status) {
              this.$notify({
                group: "error",
                type: "error",
                text: this.$t("account.generationError"),
              });
            }
          },
          (err) => {
            console.log("Err", err);
          }
        );
    },
    loadItems() {
      this.dataLoading = true;
      this.$store
        .dispatch("account/generation/" + FETCH_ACCOUNT_GENERATIONS)
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        );
    },
    loadGenerationCities: function () {
      this.isGenerationCitiesLoading = true;
      this.$store
        .dispatch("account/generation/" + FETCH_GENERATION_CITIES)
        .then((payload) => {
          this.isGenerationCitiesLoading = false;
          this.cities = payload.data;
        })
        .catch(() => {});
    },
    reset: function () {
      this.$refs.calculateForm.reset();
      this.$store.commit("account/generation/" + RESET_GENERATION_PARAMS);
    },
    generate: function () {
      this.isGenerating = true;
      this.$store
        .dispatch("account/generation/" + PUT_ACCOUNT_GENERATION)
        .then(
          () => {
            this.isGenerating = false;
            this.reset();
            this.loadItems();
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  watch: {
    pagination: {
      handler: function () {
        let query = _.merge({}, this.$route.query);
        if (this.pagination.page > 1) {
          query.page = this.pagination.page;
        } else {
          delete query.page;
        }
        this.$router
          .push({
            name: this.currentRouteName,
            query,
          })
          .catch(() => {});
        this.loadItems();
      },
      deep: true,
    },
    generation: {
      handler: function () {
        this.failedIds = this.generation
          .filter((item) => item.numGoal > 10000 || item.numGoal < 0)
          .map((item) => item.id);
      },
      deep: true,
    },
    params: {
      handler: function () {},
      deep: true,
    },
  },
  mounted() {
    this.loadItems();
    this.loadGenerationCities();
    this.failedIds = this.generation
      .filter((item) => item.numGoal > 10000 || item.numGoal < 0)
      .map((item) => item.id);
  },
};
</script>
