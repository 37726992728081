<template>
  <v-col xs="6" sm="6" md="3" lg="2">
    <v-autocomplete
      :items="citiesItems"
      item-text="title"
      item-value="id"
      :loading="isGenerationCitiesLoading"
      menu-props="closeOnContentClick"
      hide-no-data
      hide-selected
      name="city"
      :label="label"
      v-model="itemValue"
      @input="handleInput"
    >
    </v-autocomplete>
  </v-col>
</template>
<script>
import { FETCH_GENERATION_CITIES } from "@/store/actions/account-generation";

export default {
  name: "CitySetter",
  data: function () {
    return {
      itemValue: this.value,
      isGenerationCitiesLoading: false,
      label: this.$t("account.attribute.city"),
      items: [],
    };
  },
  props: {
    value: {
      type: Number,
    },
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
    },
  },
  computed: {
    citiesItems: function () {
      let items = this.items;
      items.unshift({
        id: null,
        title: this.$t("allValues"),
      });
      return items;
    },
  },
  methods: {
    loadGenerationCities: function () {
      this.isGenerationCitiesLoading = true;
      this.$store
        .dispatch("account/generation/" + FETCH_GENERATION_CITIES)
        .then((payload) => {
          this.isGenerationCitiesLoading = false;
          this.items = payload.data;
        })
        .catch(() => {});
    },
    handleInput() {
      this.$emit("input", this.itemValue);
    },
  },
  mounted() {
    this.loadGenerationCities();
  },
};
</script>
