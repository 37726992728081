<template>
  <v-sheet outlined class="mt-1">
    <v-row class="pa-3 pl-4 align-center">
      <v-col sm="1" md="1" lg="1">
        {{ indexTitle }}
      </v-col>
      <v-col sm="1" md="1" lg="1" class="d-flex justify-space-between">
        <v-icon small>
          mdi-gender-{{ getSymbolByGender({ gender: accountData.gender }) }}
        </v-icon>
        <flag :iso="getAbrByLanguage({ language: accountData.language })" />
      </v-col>
      <v-col sm="2" md="2" lg="2">
        <span class="caption">{{ accountData.ageGroup }}</span>
      </v-col>
      <v-col sm="2" md="2" lg="2">
        <span class="caption">{{ cityName }}</span>
      </v-col>
      <v-col sm="4" md="4" lg="3">
        <span class="caption">{{ accountData.coef }}</span>
      </v-col>
      <v-col sm="2" md="2" lg="3">
        <v-form ref="form">
          <v-text-field
            :rules="rules"
            v-model="accountData.numGoal"
            type="number"
            hide-details="true"
            outlined
            dense
          ></v-text-field>
        </v-form>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountDataPreset",
  data: function () {
    return {
      rules: [
        (value) => {
          const pattern = /^[0-9]+$/;
          return (
            (pattern.test(value) &&
              parseInt(value) >= 0 &&
              parseInt(value) <= 10000) ||
            "Invalid count."
          );
        },
      ],
    };
  },
  computed: {
    ...mapGetters("account/donor", [
      "availableLanguageLabels",
      "getSymbolByGender",
      "getAbrByLanguage",
    ]),
    cityName: function () {
      const city = this.cities.find(
        (city) => city.id === this.accountData.city
      );
      return city ? city.title : "-";
    },

    indexTitle: function () {
      return this.index + 1;
    },
  },
  props: {
    accountData: Object,
    cities: Array,
    index: Number,
  },
  mounted() {
    this.$refs.form.validate();
  },
};
</script>
