<template>
  <v-col xs="6" sm="6" md="3" lg="2">
    <v-autocomplete
      item-text="key"
      item-value="gender"
      :items="items"
      :label="label"
      v-model="itemValue"
      menu-props="closeOnContentClick"
      hide-no-data
      hide-selected
      name="status"
      @input="handleInput"
    >
    </v-autocomplete>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GenderSetter",
  computed: {
    ...mapGetters("account/donor", ["availableGenders"]),
    items: function () {
      let items = this.availableGenders.map(({ key, gender }) => ({
        gender,
        key: this.$t(`account.gender.${key}`),
      }));
      items.unshift({
        key: this.$t("allValues"),
        gender: null,
      });

      return items;
    },
  },
  props: {
    value: {
      type: Number,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
      closeOnContentClick: true,
      label: this.$t("account.attribute.gender"),
    };
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
    },
  },
  methods: {
    handleInput() {
      this.$emit("input", this.itemValue);
    },
  },
};
</script>
