<template>
  <v-sheet outlined class="mt-1">
    <v-row class="pa-3 pl-4 align-center">
      <v-col sm="1" md="1" lg="1" class="text-center">
        <span class="caption">{{ accountData.id }}</span>
      </v-col>
      <v-col sm="3" md="2" lg="1" class="text-center">
        <v-chip
          small
          outlined
          :color="getColorByStatus({ status: accountData.status })"
        >
          {{ status }}
        </v-chip>
      </v-col>
      <v-col
        sm="3"
        md="2"
        lg="2"
        class="d-flex justify-space-around text-center"
      >
        <v-icon>
          mdi-gender-{{ getSymbolByGender({ gender: accountData.gender }) }}
        </v-icon>
        <flag :iso="getAbrByLanguage({ language: accountData.language })" />
        <!--<br/>-->
        <span class="caption">{{ accountData.ageGroup }}</span>
      </v-col>
      <v-col sm="2" md="1" lg="1" class="text-center">
        <span class="caption">{{ cityName }}</span>
      </v-col>
      <v-col sm="3" md="2" lg="3" class="text-center">
        <span
          class="caption"
          :class="{ 'red--text': accountData.numFact !== accountData.numGoal }"
          >{{ accountData.numFact }}/{{ accountData.numGoal }}</span
        >
      </v-col>
      <v-col sm="4" md="3" lg="3" class="text-center">
        <span class="caption">{{ accountData.createdAt }}</span>
      </v-col>
      <v-col
        sm="2"
        md="1"
        lg="1"
        class="d-flex justify-space-around text-center"
      >
        <router-link
          class="text-decoration-none"
          :to="{
            name: 'AccountGenerationDetails',
            params: { generationId: accountData.id.toString() },
          }"
        >
          <v-btn text icon small v-if="showActions">
            <v-icon small> mdi-eye </v-icon>
          </v-btn>
        </router-link>
        <v-btn text icon small v-if="showActions" @click="download">
          <v-icon small> mdi-tray-arrow-down </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import downloadFile from "@/utils/file";

export default {
  name: "AccountGenerationItem",
  computed: {
    ...mapGetters("account/donor", [
      "availableLanguageLabels",
      "getSymbolByGender",
      "getAbrByLanguage",
    ]),
    ...mapGetters("account/generation", ["getColorByStatus"]),
    cityName: function () {
      return this.accountData.region ? this.accountData.region.title : "-";
    },
    showActions: function () {
      return (
        this.accountData.status === "ready" ||
        this.accountData.status === "completed"
      );
    },
    status: function () {
      return this.statuses[this.accountData.status] || "";
    },
  },
  data: function () {
    return {
      statuses: {
        new: this.$t("account.status.new"),
        processing: this.$t("account.status.processing"),
        completed: this.$t("account.status.completed"),
        failed: this.$t("account.status.failed"),
        ready: this.$t("account.status.ready"),
      },
    };
  },
  methods: {
    download: function () {
      downloadFile({
        url: `/data-generation/${this.accountData.id}/download`,
        filename: `account_data_dataset-${this.accountData.id}.jl`,
      }).catch((err) => console.log("Err", err));
    },
  },
  props: {
    accountData: Object,
    index: Number,
  },
};
</script>
