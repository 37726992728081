<template>
  <v-col xs="6" sm="6" md="3" lg="2">
    <v-text-field
      :item-text="label"
      :rules="rules"
      v-model="itemValue"
      @input="handleInput"
      type="number"
    >
      <template v-slot:label>{{ label }}<sup>*</sup> </template>
    </v-text-field>
  </v-col>
</template>

<script>
export default {
  name: "CountSetter",
  props: {
    value: {
      type: [Number, String],
    },
  },
  data: function () {
    return {
      label: this.$t("account.count"),
      itemValue: this.value,
      rules: [
        (value) => !!value || this.$t("account.requiredField"),
        (value) => {
          const pattern = /^[0-9]+$/;
          return pattern.test(value) || this.$t("account.invalidCount");
        },
        (value) => {
          const pattern = /^[0-9]+$/;
          return (
            (pattern.test(value) &&
              parseInt(value) > 0 &&
              parseInt(value) <= 10000) ||
            this.$t("account.invalidCountLimit", { value: 10000 })
          );
        },
      ],
    };
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
    },
  },
  methods: {
    handleInput() {
      this.$emit("input", parseInt(this.itemValue));
    },
  },
};
</script>
